@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  display: flex;
  margin-bottom: 25px;
  flex-direction: column;
  position: relative;

  &.--bad {
    .answer {
      color: $venetian-red;
    }
  }

  &.--average {
    .answer {
      color: $gold;
    }
  }

  &.--good {
    .answer {
      color: $lime;
    }
  }

  &.--logicChild {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 11px;
      border-right: 2px solid $base-grey-10;
      z-index: 1;
    }

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      height: 25px;
      width: 11px;
      border-right: 2px solid $base-grey-10;
      z-index: 1;
    }
  }

  &.--title {
    display: flex;
    margin-bottom: 2em;

    .question {
      font-size: 11px;
      color: $brand-primary-160 !important;
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  > div {
    font-size: 13px;
  }

  &:not(.title) {
    .answer {
      padding-top: 5px;
      margin-left: 30px;
      font-style: italic;
      font-size: 13px;
      color: $brand-primary-200;

      .scaleLabel {
        color: $brand-primary-200;

        &.--poor {
          padding-right: 10px;
        }

        &.--fantastic {
          padding-left: 10px;
        }
      }

      .scale {
        color: $brand-primary-5;
        margin: 0 5px;

        &.--selected {
          color: $brand-primary-160;
          font-weight: 600;
        }
      }

      .rating {
        svg {
          color: $brand-primary-160;
          height: 20px;
        }

        &.--selected {
          svg {
            fill: $brand-primary-160;
          }
        }
      }

      .option {
        color: $brand-primary-5;
        margin: 0 8px;

        &:nth-child(1) {
          margin: 0;
        }

        &.--selected {
          color: $brand-primary-160;
          font-weight: 600;
        }
      }

      .signature {
        max-height: 100px;
      }

      .text {
        white-space: pre-wrap;
      }
    }
  }
}

.questionRow {
  display: flex;
  z-index: 2;
}

.number {
  color: $base-grey-90;
  display: inline-block;
  text-align: center;
  height: 20px;
  width: 20px;
  background-color: $surface-secondary;
  border-radius: 50%;
  border: 1px solid $base-grey-10;
  margin-right: 10px;
  min-width: 20px;
  z-index: 2;
}

.question {
  color: $black;
  font-weight: 500;

  a {
    color: $brand-primary-40;
  }
}
