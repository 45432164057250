@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.permission {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px;

  &:not(:last-of-type) {
    border-bottom: 1px solid $brand-primary-15;
    padding-bottom: 15px;
  }
}
