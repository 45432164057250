@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  width: 100%;
  height: 40px;
  overflow: hidden;
  display: flex;

  .step1 {
    p {
      color: $white;
    }
  }

  .step2 {
    p {
      color: $brand-primary-25;
    }
  }

  &--active1 {
    .step1 {
      background-color: $brand-primary-80;

      p::before {
        content: '» ';
      }
    }

    .step2 {
      background-color: $white;
      border: 1px solid $brand-primary-80;
    }

    &.root--invalid {
      .step1 {
        background-color: $red;
        border-color: $red;

        &::after {
          border-left-color: $red;
        }
      }
    }
  }

  &--active2 {
    .step1 {
      background-color: $brand-primary;

      &::after {
        border-left: 20px solid $brand-primary;
      }
    }

    .step2 {
      background-color: $brand-primary-25;

      p {
        color: $white;
      }

      p::before {
        content: '» ';
      }
    }
  }
}

@mixin step {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  position: relative;
  color: $white;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;

  p {
    margin: 0;
  }

  svg {
    height: 15px;
  }
}

.step1 {
  @include step;
  z-index: 2;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: 100%;
    border-top: 20px solid transparent;
    border-left: 20px solid $brand-primary-80;
    border-bottom: 20px solid transparent;
  }
}

.step2 {
  @include step;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
