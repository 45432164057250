@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  transition: all 0.4s;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  background-color: $white;
  color: $brand-primary-200;
  border: 1px solid $base-grey-12;

  &:hover {
    border: 1px solid $brand-primary;
  }
}
