@use 'styles/mixins' as *;

.root {
  position: relative;
  width: 100%;
  display: flex;
  height: 100vh;
  background-color: $surface-secondary;

  .content {
    margin: 30px;
    @include checkmate-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 450px;
    padding: 30px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    opacity: 1;
    transition: all 0.4s ease-in-out;
    max-height: 90%;
    overflow: scroll;
  }

  .candidateDetails {
    &Hidden {
      transform: translateX(-100vw);
      opacity: 0;
    }
  }

  .checkDetails {
    &Hidden {
      transform: translateX(100vw);
      opacity: 0;
    }
  }
}
