@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  padding-bottom: 2em;

  .section {
    margin-bottom: 50px;
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 25px;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }

    .title {
      display: flex;
      align-items: flex-start;
      color: $base-grey-35;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 500;
    }

    p {
      padding-top: 1em;
      font-size: 22px;
      color: #5b5f84;
      font-style: oblique;
      font-weight: 300;
    }
  }

  .comments {
    margin-top: 2em;
    font-size: 22px;
    color: #5b5f84;
  }

  .securityLayer {
    .securityRow {
      margin-bottom: 1em;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .details {
        padding-left: 1em;

        div {
          display: inline-block;

          &.invalid {
            color: #ef113f;
            cursor: pointer;
          }

          &.valid {
            color: #2ec45f;
          }
        }

        & > a {
          color: #915dec;
        }
      }
    }
  }
}
