@use 'styles/mixins' as *;

.root {
  @include flex-box-centered;

  display: inline-flex;
  width: 3.5rem;
  height: 3.5rem;
  min-width: 3.5rem;
  min-height: 3.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.4s ease-in-out;

  &.default {
    color: $base-grey-50;
    background-color: $surface-secondary;
    border: 1px solid $base-grey-10;

    @include pseudo-states() {
      background-color: $brand-color-dark;
      color: $white;
    }
  }

  &.white {
    color: $base-grey-50;
    background-color: $white;
    border: 1px solid $base-grey-10;

    @include pseudo-states() {
      background-color: $brand-color-dark;
      color: $white;
    }
  }

  &.delete {
    color: $red;
    background-color: $surface-secondary;
    border: $grey-border;

    @include pseudo-states() {
      background-color: $red-150;
      border-color: $red-150;
      color: $white;
    }
  }

  &Disabled {
    cursor: not-allowed !important;
    color: $base-grey-10 !important;
    background-color: $surface-secondary !important;

    @include pseudo-states() {
      color: $base-grey-10 !important;
      background-color: $surface-secondary !important;
    }
  }

  &Small {
    width: 3.1rem;
    height: 3.1rem;
    min-width: 3.1rem;
    min-height: 3.1rem;
  }

  &Active {
    background-color: $brand-color-dark !important;
    color: $white !important;

    @include pseudo-states() {
      background-color: $brand-color !important;
      color: $white !important;
    }
  }
}
