@use 'styles/mixins' as *;

.root {
  background-color: $neutral-01 !important;
  border: $grey-border;
  border-radius: 24px;
}

.floatingFilters {
  padding: 15px;
  border: $grey-border;
  border-radius: 24px;

  .filterInputs {
    display: flex;
    align-items: center;
  }

  .filterValues {
    margin-top: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  .filter {
    width: auto;
    margin-left: 15px;
  }

  .filterPill {
    display: flex;
    align-items: center;
    border: 1px solid $base-grey-10;
    border-radius: 1000px;
    overflow: hidden;

    &Icon {
      margin-right: 5px;
    }

    &Title {
      display: flex;
      align-items: center;
      padding: 5px 10px;
      background-color: $surface-secondary;
    }

    &Value {
      display: flex;
      align-items: center;
      padding: 5px 10px;
      background-color: $white;
      border-left: 1px solid $base-grey-10;
    }

    &Delete {
      padding: 2px;
      border-radius: 50%;
      background-color: $brand-color-dark;
      color: $white;
      margin-left: 5px;
      cursor: pointer;
    }
  }

  .left {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .right {
    border-left: $grey-border;
    padding-left: 15px;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.filters {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .left {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .right {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .search {
    width: 50%;
    max-width: 30em;
  }

  .filter {
    width: 15em;
    margin-left: 3em;
  }
}

.table {
  width: 100%;
  border-collapse: separate;

  .head {
    height: 40px;
  }

  .body {
    position: relative;

    .row {
      height: 56px;
    }
  }

  .row {
    position: relative;
  }

  .column {
    padding: 7px;
    border-bottom: $grey-border;
    position: relative;
    z-index: 2;

    &:first-child {
      padding-left: 15px;
    }

    &:last-child {
      padding-right: 15px;
    }
  }

  .header {
    background-color: $background-secondary;
    vertical-align: middle;
    position: relative;
    z-index: 2;
    font-weight: 400;
    padding: 2px 5px;
    border-bottom: $grey-border;
    border-top: $grey-border;

    &:first-child {
      padding-left: 15px;
    }

    &:last-child {
      padding-right: 15px;
    }

    &NoBorder {
      border-top: none !important;
    }
  }

  .footer {
    .chevron {
      margin-bottom: -4px;

      &Disabled {
        cursor: not-allowed;
        color: $base-grey-40;

        &:hover {
          color: $base-grey-40;
        }
      }
    }

    .pagination {
      &Description {
        @include text-small;
        padding: 15px;
      }

      &Links {
        padding: 15px;
        text-align: end;
      }

      .paginationNumber {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 24px;
        height: 24px;
        border: $grey-border;
        border-radius: 8px;
        background-color: $surface-secondary;
        margin: 0 4px;

        &Selected {
          border-color: $brand-color-dark;
        }
      }
    }
  }
}

.bulk {
  &Actions {
    position: fixed;
    background-color: $white;
    box-shadow: 2px 4px 14px 0px $base-grey-60;
    width: fit-content;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 20px auto;
    border-radius: 8px;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: bottom 0.4s ease-in-out;
    padding: 15px 20px;

    &Hidden {
      bottom: -100px;
    }

    &Badge {
      margin-right: 30px;
      background-color: $brand-primary-120;
      cursor: pointer;
    }

    &Wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &Action {
    &Icon {
      cursor: pointer;
      color: $brand-primary-120;
    }

    &IconWrapper {
      width: 24px;
      height: 24px;
      margin: 0 7px;
    }
  }
}

.placeholder {
  position: absolute;
  left: 0;
  right: 0;
  top: -8px;
  text-align: center;
}
