@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  min-width: 300px;
  margin-top: 30px;
  background-color: $surface-secondary;
  border: 1px solid $base-grey-15;
  border-radius: 8px;
  padding: 5px 15px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 10px 0;

  &Description {
    text-transform: capitalize;
    font-weight: 600;
    margin-right: 30px;
  }

  &Value {
    max-width: 70%;
    text-align: end;
  }
}
