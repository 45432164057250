@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  min-width: 30px;
  min-height: 30px;
  color: $white;
  border: none;
  transition:
    color,
    background-color 0.5s;
  -webkit-transition:
    color,
    background-color 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 14px;
    height: 14px;
  }
}
