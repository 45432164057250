@use 'styles/mixins' as *;

.root {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 1.2rem;
  border: 1px solid $base-grey-12;
  background-color: $surface-secondary;
  border-radius: 6px;
  height: 4rem;
  margin-bottom: 15px;

  label {
    display: flex;
    align-items: center;
    padding-left: 0;
    margin: 0;
    font-weight: 400;

    .tooltip {
      display: flex;
      align-items: center;

      svg {
        color: $base-grey-35;
        height: 17px;
        margin-left: 2px;
        cursor: pointer;
      }
    }
  }

  .slider {
    position: relative;
    width: 30px;
    height: 18px;

    input {
      position: absolute;
      z-index: 2;
      zoom: 2;
      top: -2px;
      left: 1px;
      margin: 0;
      cursor: pointer;
      opacity: 0;

      &:disabled {
        cursor: not-allowed;

        & + span {
          opacity: 0.5;
        }
      }
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $base-grey-25;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 33px;

      &:before {
        position: absolute;
        content: '';
        height: 14px;
        width: 14px;
        left: 2px;
        top: 2px;
        border-radius: 50%;
        background-color: $white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }

    input:checked + span {
      background-color: $lime;

      &::before {
        transform: translateX(12px);
      }
    }
  }
}
