@use 'styles/utils' as *;

.overlayCardTrigger {
  position: relative;
  .overlayCardSection {
    position: absolute;
    display: none;
    &[class*='top'] {
      top: -75px;
    }
    &[class*='bottom'] {
      bottom: -75px;
    }
    left: 0;
    z-index: 10;
  }
  &:hover {
    .overlayCardSection {
      display: block;
    }
  }
}
