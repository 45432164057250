@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  margin-bottom: 15px;
  position: relative;
  z-index: 2;

  .order {
    color: $base-grey-90;
    display: inline-block;
    text-align: center;
    height: 20px;
    width: 20px;
    background-color: $surface-secondary;
    border-radius: 50%;
    border: 1px solid $base-grey-10;
    margin-right: 10px;
    min-width: 20px;
    z-index: 2;
  }

  &:not(:last-child):not(.header)::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% + 20px);
    width: 11px;
    border-right: 2px solid $base-grey-10;
    z-index: 1;
  }

  &.header {
    margin-bottom: 20px;

    .title {
      padding: 0;
    }

    .answersRows .referees {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 11px;
      color: $base-grey-35 !important;
    }
  }

  .title {
    padding-left: 30px;
  }

  .question {
    margin-bottom: 12px;
  }

  .answersRows {
    .candidate {
      padding-bottom: 5px;
    }

    .answersColumns {
      display: table;
      table-layout: fixed;

      div {
        padding-right: 10px;
        display: table-cell;
      }
    }
  }
}
