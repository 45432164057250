@use 'styles/mixins' as *;

.root {
  position: relative;

  .icon {
    position: absolute;
    color: $text-secondary;
    top: 50%;
    margin-top: calc(-1.8rem / 2);
    margin-left: calc(1.5rem - 1.8rem / 2);
    height: 1.8rem;
    width: 1.8rem;
  }

  .input {
    @include input;

    padding-left: 3rem;
  }

  .menu {
    position: absolute;
    display: none;
    background-color: $white;
    min-width: 100%;
    max-width: 50rem;
    z-index: 99;
    padding: 0;
    box-shadow: 0px 6px 10px 0px #00000033;
    border-radius: 6px;

    &Visible {
      display: block;
    }

    .item {
      @include text-body;
      list-style: none;
      padding-inline-start: 0;
      padding: 1.2rem;
      cursor: pointer;
      &:hover {
        background-color: $base-grey-10;
      }

      &Hidden {
        display: none;
      }
    }

    ul {
      overflow-y: scroll;
      max-height: 20rem;
      padding: 0;
      margin: 0;
    }

    .itemDescription {
      white-space: nowrap;
      color: $base-grey-50;
      font-size: 1rem;
      font-size: 1.3rem;
      margin: 0.5rem 0;
    }
  }

  .selectedValue {
    background-color: $neutral-02 !important;
  }
}
