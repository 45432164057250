@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.details {
  background-color: $white;
  padding: 2em;
}

.filtersModalForm {
  width: 300px;
}
