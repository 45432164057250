@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  height: 100vh;
  width: 100vw;
  background-color: $white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  overflow: auto;

  .message {
    border-radius: 8px;
    text-align: center;
    padding: 10px;
    font-size: 12px;
    text-transform: uppercase;
  }

  .icon {
    margin-bottom: 5px;
  }

  &Info {
    .message {
      border: 1px solid $brand-primary-5;
      color: $brand-primary-160;
    }
  }

  &Error {
    .message {
      border: 1px solid $status-error;
      color: $status-error;
    }
  }
}
