@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  position: relative;
  width: 830px;
  min-height: 780px;
  border-radius: 8px;
  background-color: $white;
  border: solid 1px $brand-primary-5;
}

.header {
  margin: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headline {
  padding: 0;
  text-transform: uppercase;
  color: #5c4e79;
  font-weight: 500;
  font-size: 12px;
}

.name {
  color: #2e263e;
  font-size: 24px;
  font-weight: bold;
}
