@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  margin-bottom: 20px;

  .row {
    margin-bottom: 10px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: calc(100% + 20px);
      width: 11px;
      border-right: 2px solid $base-grey-10;
      z-index: 1;
    }

    .order {
      color: $base-grey-90;
      display: inline-block;
      text-align: center;
      height: 20px;
      width: 20px;
      background-color: $surface-secondary;
      border-radius: 50%;
      border: 1px solid $base-grey-10;
      margin-right: 10px;
      min-width: 20px;
      z-index: 2;
    }
  }

  .answers {
    margin-left: 30px;

    .answer {
      margin-bottom: 5px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 6px;
        left: -23px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $base-grey-10;
      }

      &:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 0;
        left: -30px;
        height: calc(100% + 15px);
        width: 11px;
        border-right: 2px solid $base-grey-10;
        z-index: 1;
      }
    }
  }
}
