@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  min-width: 400px;
  margin-top: 30px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;

  &Title {
    border-bottom: 1px solid $brand-primary-5;
    margin-bottom: 20px;

    .rowValue {
      font-weight: 600;
    }
  }

  &Value {
    width: 32%;
    text-align: right;
  }

  &Description {
    width: 32%;
    font-weight: 600;
  }
}
