@use 'styles/mixins' as *;

.root {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 15px;

  .error {
    color: $error-color;
    font-size: 1.1rem;
    text-transform: capitalize;

    &::before {
      content: '* ';
    }
  }

  .select {
    font-size: 1.4rem;
    font-weight: 400;
    font-family: inter, sans-serif;
    background-color: $surface-secondary;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    height: 4rem;
    padding: 0 2.5rem 0 0.8rem;
    transition: border-color 0.4s ease-in-out;
  }
}
