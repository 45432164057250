@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  background-color: $white;
  border-radius: 5px;
  min-width: 100px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  .image {
    width: 100%;
    height: 100%;

    &Disabled {
      width: 0;
      height: 0;
    }
  }
}
