@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  background-color: $surface-secondary;
  min-height: 100vh;

  .tabs {
    .iqTitle {
      img {
        height: 12px;
        margin-bottom: 2px;
      }
    }
  }
}
