@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.linkedinBtn .socialIcon {
  position: absolute;
  top: 7px;
  left: 12px;
  height: 26px;
  width: auto;
}
.linkedinBtn {
  position: relative;
  cursor: pointer;
  width: 300px;
  height: 50px;
  background-color: #1b83bb;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: $white;
  transition: all 0.4s;
  border-radius: 8px;
}
.linkedinBtn .socialIcon {
  top: 11px;
}
.linkedinBtn:hover {
  background-color: #2476a1;
}
