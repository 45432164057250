@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.disabled {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.4;
    background-color: $base-grey-50;
    border-radius: 10px;
  }
}

.logo {
  width: 25px;
  height: 25px;
}

.adminState {
  position: absolute;
  left: -1%;
  width: 14px;
  height: 14px;
  border-radius: 14px;
  top: -7px;
  background-color: $status-pending-secondary;
  animation: pulseSupport 2s infinite;

  &.pending {
    background-color: $status-in-progress-secondary;
    animation: pulsePending 2s infinite;
  }

  &.error {
    background-color: $status-error-secondary;
    animation: pulseError 2s infinite;
  }
}

.candidateCheckRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@keyframes pulseSupport {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(197, 160, 58, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(197, 160, 58, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(197, 160, 58, 0);
  }
}

@keyframes pulseError {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(173, 49, 49, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(173, 49, 49, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(173, 49, 49, 0);
  }
}

@keyframes pulsePending {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(54, 109, 180, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(54, 109, 180, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(54, 109, 180, 0);
  }
}
