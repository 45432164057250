@use 'styles/grid' as *;
@use 'styles/mixins' as *;

.step {
  p {
    color: $base-grey-90;
  }
  .card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    background: $surface-secondary;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 24px;
  }

  .mainTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
  }

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .p18 {
    font-size: 18px;
    line-height: 26px;
  }

  .p16 {
    font-size: 16px;
    line-height: 24px;
  }

  .accLinkWrapper {
    @include flex-box-justify;
  }

  .accLink {
    padding: 10px 30px;
    display: inline-flex;
    align-items: center;
    background-color: #015e8f;
    color: $white;
    border-radius: 1000px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    margin-right: 20px;
    text-decoration: none;
  }

  .elseDivider {
    margin-top: 34px;
    font-weight: 600;
    font-size: 17px;
  }
  .email,
  .email:hover {
    color: $base-grey-90;
  }
}

@media only screen and (max-width: $tablet) {
  .step {
    .mainTitle {
      font-size: 24px;
    }
    .p18 {
      font-size: 16px;
      line-height: 22px;
    }
    .accLinkWrapper {
      display: inline;
      width: 100%;
    }
    .accLink {
      margin: 10px auto;
      width: 100%;
      display: block;
      text-align: center;
      font-size: 16px;
      padding: 2px 30px;
    }
  }
}
