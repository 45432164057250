@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  padding: 30px;

  .banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $base-grey-35;

    .logo {
      text-transform: uppercase;
    }

    img {
      height: 25px;
      margin-bottom: 10px;
      margin-left: 5px;
    }
  }

  .candidate {
    border-bottom: 1px solid #e5dff2;
  }

  .candidate,
  .referee {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 25px;
    padding-bottom: 30px;

    & > div:first-child {
      width: 30%;
    }

    & > div:not(:first-child) {
      width: 17%;
    }

    .name {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      .label {
        padding: 0;
        text-transform: uppercase;
        color: $base-grey-35;
        font-weight: 500;
        font-size: 12px;
        padding-bottom: 5px;
      }

      .value {
        color: #2e263e;
        font-size: 20px;
        font-weight: bold;
      }
    }

    .infoField {
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      padding-left: 2em;

      p {
        margin: 0;
      }

      .title {
        text-transform: uppercase;
        color: $base-grey-35;
        font-size: 10px;
        font-weight: 500;
        padding-bottom: 7px;
      }

      .value {
        color: #2e263e;
        font-weight: 500;
      }
    }
  }

  .verificationCards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
