.modal {
  min-width: 700px;
  max-width: 700px;
  margin-top: 20px;
}

.previewText {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  margin: 10px 0;
}

.previewRow {
  padding: 3px 8px;
}
