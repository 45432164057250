@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  position: relative;
  display: block;
  cursor: pointer;
  background-color: $white;
  border-radius: 10px;
  flex: 1;

  &Rejected {
    &::after {
      content: 'DECLINED';
      position: absolute;
      top: -12px;
      right: -6px;
      background-color: $status-error;
      color: $white;
      font-size: 10px;
      padding: 2px 5px;
      font-weight: 500;
      border-radius: 3px;
    }
  }

  .image {
    position: relative;
    padding: 10px;
    max-width: 300px;
    display: inline-block;
    transition: transform 0.4s ease;
    z-index: 1;

    &:hover {
      z-index: 2;
      transform: scale(1.1);
    }
  }

  .checkedIcon {
    position: absolute;
    color: $viking;
    height: 18px;
    width: 18px;
    left: 10px;
    top: 20px;
    z-index: 10;
  }

  .pdfIcon {
    margin: 30px;
    height: 30px;
    width: 30px;
    color: $base-grey-80;
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.imageCard {
  height: 100%;
}

.filePreviewContainer {
  border: 1px solid $base-grey-10;
  border-radius: 8px;
  background-color: $base-grey-5;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .filePreviewContent,
  .filePreviewFooter {
    padding: 16px;
  }
  .filePreviewFooter {
    border-top: 1px solid $base-grey-10;
  }
  .fileType {
    font-size: 12px;
    font-weight: 500;
    background-color: $white;
    padding: 4px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    color: $base-grey-80;
    border: 1px solid $base-grey-10;
    align-self: center;
    border-radius: 24px;
  }
}

.filePreviewContent {
  position: relative;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbnail {
  max-width: 100%;
  height: auto;
}

.imageWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
