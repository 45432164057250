@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  display: flex;
  margin-bottom: 25px;
  flex-direction: column;
  position: relative;

  > * {
    font-size: 13px;
  }

  &::after {
    content: '';
    position: absolute;
    height: calc(100% - 15px);
    width: 11px;
    border-right: 2px dotted $base-grey-10;
    z-index: 1;
  }
}

.question {
  position: relative;

  &Order {
    color: $base-grey-90;
    display: inline-block;
    text-align: center;
    height: 20px;
    width: 20px;
    background-color: $surface-secondary;
    border-radius: 50%;
    border: 1px solid $base-grey-10;
    margin-right: 10px;
    min-width: 20px;
    z-index: 2;
  }

  &Text {
    color: $black;
    font-weight: 500;
  }
}

.answers {
  margin-top: 15px;
  margin-left: 30px;

  .answer {
    margin-bottom: 15px;
    position: relative;
    z-index: 2;

    &::before {
      content: '';
      position: absolute;
      top: calc(50% - 3px);
      left: -24px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $base-grey-10;
    }
  }

  .subAnswers {
    position: relative;
    margin-bottom: 15px;

    &::after {
      content: '';
      position: absolute;
      top: -15px;
      height: 100%;
      width: 15px;
      border-right: 2px dotted $base-grey-10;
      z-index: 1;
    }

    .subAnswer {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: calc(50% - 3px);
        left: -20px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $base-grey-10;
      }
    }
  }
}

.badge {
  color: $brand-primary-105;
  background-color: $brand-primary-70;
}

.answer {
  .scale {
    &Label {
      color: $brand-primary-200;

      &Left {
        padding-right: 10px;
      }
      &Right {
        padding-left: 10px;
      }
    }

    &Number {
      color: $brand-primary-5;
      margin: 0 5px;

      &Selected {
        color: $brand-primary-160;
        font-weight: 600;
      }
    }
  }

  .upload {
    height: auto;
    font-size: 12px;
    padding: 3px 20px;

    & > svg {
      width: 13px;
    }
  }

  .rating {
    svg {
      color: $brand-primary-160;
      height: 20px;
    }

    .starFilled {
      svg {
        fill: $brand-primary-160;
      }
    }
  }

  .multipleChoice {
    &Option {
      color: $brand-primary-5;
      margin-right: 8px;

      &Selected {
        color: $brand-primary-160;
        font-weight: 600;
      }
    }
  }

  .signature {
    max-height: 100px;
  }

  .text {
    white-space: pre-wrap;
  }
}
