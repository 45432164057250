@use 'styles/mixins' as *;

.root {
  @include input;
  height: initial;
  padding: 1.2rem;
  resize: none;
  border: 0;
}

/* Targeting the textarea specifically inside the mention-input */
.mention-input textarea {
  border: 2px solid #3498db !important; /* Change to desired border color */
}

/* Optional: Target when the textarea is focused */
.mention-input textarea:focus {
  border-color: #e74c3c !important; /* Change to the desired focused color */
  outline: none;
}
