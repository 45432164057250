@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  height: 100vh;
  width: 100vw;
  background-color: $white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  overflow: auto;

  .wrapper {
    max-width: 450px;
    width: 90%;
  }

  .inProgress {
    &Wrapper {
      border-radius: 8px;
      border: 1px solid $brand-primary-5;
    }

    &Message {
      color: $brand-primary-160;
      text-align: center;
      padding: 15px;
      padding-top: 0;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}
