/////////////////////////////////// COLORS /////////////////////////////////////

/***** NEUTRAL *****/

$neutral-01: #ffffff;
$neutral-02: #f6f7ff;
$neutral-03: #f0f0f8;
$neutral-04: #e1e1ec;
$neutral-05: #b0a2cf;
$neutral-06: #5c4d78;
$neutral-07: #2d264d;
$neutral-08: #1f1938;
$neutral-09: #100d20;
$neutral-10: #000000;

/***** PRIMARY *****/

$primary-01: #8047f233;
$primary-02: #9289e8;
$primary-03: #8479e6;
$primary-04: #8047f2;
$primary-05: #4e18bb;
$primary-06: #390a98;

/***** BLUE *****/

$blue-01: #2f80ed33;
$blue-02: #0055ff7f;
$blue-03: #2f80ed;
$blue-04: #155cbd;

/***** RED *****/

$red-01: #eb575733;
$red-02: #eb5757;
$red-03: #c32d2d;

/***** GREEN *****/

$green-01: #039d4133;
$green-02: #039d41;
$green-03: #02722f;

/***** YELLOW *****/

$yellow-01: #eeca0033;
$yellow-02: #eeca00;
$yellow-03: #b49900;

/***** ORANGE *****/

$orange-01: #ff7a0033;
$orange-02: #ff7a00;
$orange-03: #d86700;

/***** OTHERS *****/

$grey-01: #2d264d33;
$neutral-light: #5c4d7899;
$half-white: #f8ffe5;
$dessert-sand: #f87070;

/***** GRADIENTS *****/

$gradient-primary: linear-gradient(0deg, #d0cef6 0%, #e2e0f9 100%);
$gradient-primary-border: linear-gradient(0deg, #b9b8f34d 35%, #c3bffe4d 100%);
