@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  display: flex;
  margin-bottom: 25px;
  position: relative;

  .logicChild {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 10px;
      border-right: 2px solid #e5dff2;
      z-index: 1;
    }

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      height: 25px;
      width: 10px;
      border-right: 2px solid #e5dff2;
      z-index: 1;
    }
  }

  .number {
    color: $base-grey-90;
    display: inline-block;
    text-align: center;
    height: 20px;
    width: 20px;
    background-color: $surface-secondary;
    border-radius: 50%;
    border: 1px solid $base-grey-10;
    margin-right: 10px;
    min-width: 20px;
    z-index: 2;
  }

  &.title {
    display: flex;
    align-items: flex-start;
    color: $base-grey-35;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    margin-bottom: 2em;

    & > div {
      font-size: 11px;
      color: $base-grey-35 !important;
    }
  }

  &:not(.title) {
    .candidateAnswer,
    .refereeAnswer {
      font-style: italic;
    }
  }

  & > div {
    font-size: 13px;
  }

  .text {
    width: 60%;
    color: #000000;
    font-weight: 500;
  }

  .candidateAnswer,
  .refereeAnswer {
    margin-left: 1em;
    width: 19%;
    color: #000000;
  }

  &.equals {
    .candidateAnswer,
    .refereeAnswer {
      color: #46e179;
    }
  }
}
