@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  display: flex;
  align-items: center;
  flex-direction: column;

  .icon {
    min-width: 40px;
    max-width: 70px;
    max-height: 70px;
  }
}
