@import 'utils';
@import 'colors';

.overlayCard {
  @extend .u-border-radius--x-small;
  @extend .u-drop-shadow--medium;
  background-color: $white;
  width: max-content;
}

.user-info-container {
  &:hover {
    background-color: $base-grey-5;
  }
}

.mention-input {
  textarea {
    border: 1px solid $base-grey-10 !important;
    border-radius: 10px !important;
    padding: 8px !important;
  }
}
