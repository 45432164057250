@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.option {
  width: 100%;

  .icon {
    color: $base-grey-50;
  }

  .input {
    width: 100%;

    input {
      background-color: $white !important;
    }
  }
}
