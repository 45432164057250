@use 'styles/mixins' as *;

.item {
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 25px;
    width: 25px;
    height: 25px;
    color: $base-grey-50;
    background-color: $surface-secondary;
    border: 1px solid $base-grey-10;
    border-radius: 50%;
    margin-right: 7px;
  }

  & > div {
    padding-top: 3px;
  }
}
