@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  position: relative;
}

.closeIcon {
  background-color: $status-error;
  color: $white;
  border-radius: 50%;
  position: absolute;
  top: -8px;
  right: -8px;
  z-index: 2;
  cursor: pointer;
}

.table {
  background-color: $white;
  border-radius: 8px;
  position: relative;
  max-width: 450px;
  max-height: 450px;
  overflow: scroll;
  display: block;

  thead {
    background-color: $brand-primary;
    color: $white;
  }

  th,
  td {
    white-space: nowrap;
    padding: 10px;
  }
}
