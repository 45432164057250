@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  position: relative;
  width: 700px;
  border-radius: 8px;
  background-color: $white;
  border: solid 1px $brand-primary-5;

  .header {
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid $brand-primary-15;
  }

  .details {
    .title {
      text-transform: uppercase;
      color: $brand-primary-160;
      font-weight: 500;
      font-size: 12px;
    }

    .name {
      color: $brand-primary-200;
      font-size: 24px;
      font-weight: bold;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
  }
}
