@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  display: flex;
  margin-bottom: 25px;
  flex-direction: column;
  position: relative;

  > * {
    font-size: 13px;
  }

  .question {
    display: flex;
    z-index: 2;

    &Order {
      color: $base-grey-90;
      display: inline-block;
      text-align: center;
      height: 20px;
      width: 20px;
      background-color: $surface-secondary;
      border-radius: 50%;
      border: 1px solid $base-grey-10;
      margin-right: 10px;
      min-width: 20px;
    }

    &Text {
      color: $black;
      font-weight: 500;
    }
  }

  .answer {
    padding-top: 5px;
    margin-left: 30px;
    font-style: italic;
    font-size: 13px;
    color: $brand-primary-200;

    .scale {
      &Label {
        color: $brand-primary-200;

        &Left {
          padding-right: 10px;
        }
        &Right {
          padding-left: 10px;
        }
      }

      &Number {
        color: $brand-primary-5;
        margin: 0 5px;

        &Selected {
          color: $brand-primary-160;
          font-weight: 600;
        }
      }
    }

    .rating {
      svg {
        color: $brand-primary-160;
        height: 20px;
      }

      .starFilled {
        svg {
          fill: $brand-primary-160;
        }
      }
    }

    .multipleChoice {
      &Option {
        color: $brand-primary-5;
        margin-right: 8px;

        &Selected {
          color: $brand-primary-160;
          font-weight: 600;
        }
      }
    }

    .signature {
      max-height: 100px;
    }

    .text {
      white-space: pre-wrap;
    }
  }
}
