@import 'styles/tokens';

.root {
  position: absolute;
  height: 130px;
  width: 450px;
  border-radius: 8px;
  background-color: $surface-secondary;
  box-shadow: 0 8px 24px 0 rgba(46, 38, 62, 0.2);
  top: 55px;
  right: 150px;
  z-index: 2;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;

  &.disabled {
    opacity: 0;
    z-index: -2;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .title {
    color: #2e263e;
    font-size: 16px;
    font-weight: 700;
  }

  .copy {
    color: #7a48df;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
  }

  input {
    width: 100%;
    height: 50px;
    border: 1px solid #e5dff2;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: inset 0 0 2px 0 #7cb7ff;
    outline: none !important;
    padding-left: 20px;
    padding-right: 40px;
    box-shadow: none;
    -webkit-box-shadow: none;
    transition: border-color 0.4s;
    -webkit-transition: border-color 0.4s;
  }
}
