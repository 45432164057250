@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  border: 1px solid #e5dff2;
  border-radius: 8px;
  width: 32%;
  padding: 1em;
  overflow: hidden;

  :global(.title) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 10px;
    text-transform: uppercase;
    color: $base-grey-35;
    margin-bottom: 4px;
    font-weight: 600;

    svg {
      height: 17px;
      margin-right: 5px;
    }
  }

  a {
    padding-left: 4px;
    color: #7a48df;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  :global(.static-value) {
    padding-left: 4px;
    color: #60527c;
  }

  :global(.verification) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 4px;
    font-weight: 500;
    font-size: 12px;

    svg {
      height: 13px;
      margin-right: 1px;
    }

    &:global(.warn) {
      color: #ef6611;
    }
    &:global(.error) {
      color: #ef113f;
    }
    &:global(.success) {
      color: #2ec45f;
    }
  }
}
