@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(46, 38, 62, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;

  &Hidden {
    display: none;
  }

  .content {
    width: 400px;
    min-height: 220px;
    color: $brand-primary-200;
    background-color: $white;
    border-radius: 15px;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &Title {
      text-align: left;
      font-size: 20px;
      font-weight: 700;
    }

    &Message {
      margin-top: 15px;
      font-size: 14px;
    }

    &Buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
    }
  }
}
