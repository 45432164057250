@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  width: 450px;
}

.cancelBtn {
  text-align: center;
  color: $brand-primary-200;
  width: 100%;
  display: block;
  font-weight: 500;
  margin-top: 2em;
  cursor: pointer;
}
