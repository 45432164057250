@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.checks {
  display: flex;
  align-items: center;

  .check {
    margin-right: 5px;
  }
}
