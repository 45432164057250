@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  margin: 30px;

  .section {
    margin-bottom: 50px;
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 25px;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }

    .header {
      font-weight: 500;
      font-size: 11px;
      color: $base-grey-35 !important;
      margin-bottom: 20px;
    }

    :global(.order) {
      color: $base-grey-35;
      display: inline-block;
      text-align: center;
      height: 20px;
      width: 20px;
      background-color: #e5dff2;
      border-radius: 50%;
      margin-right: 10px;
    }

    .question {
      color: #000000;
      font-weight: 500;
    }
  }
}
