@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.userSelector {
  display: flex;
  padding: 15px;
}

.usersHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid $brand-primary-15;
  border-top: 1px solid $brand-primary-15;
  background-color: $white;
  margin-top: 15px;

  &Content {
    display: flex;
    width: 100%;
    margin: 0 15px;
    padding: 10px 15px;

    & > div:nth-child(1) {
      width: 30%;
    }

    & > div:nth-child(2) {
      width: 50%;
    }
  }
}

.userRows {
  padding: 15px 0;

  .userRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;

    &:not(:first-child) {
      margin-top: 15px;
    }

    .column {
      &:nth-child(1) {
        width: 30%;
      }

      &:nth-child(2) {
        width: 50%;
      }

      &:nth-child(3) {
        text-align: right;
        width: 20%;
      }
    }
  }
}
