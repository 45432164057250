@use 'styles/mixins' as *;

.root {
  position: relative;
  padding-top: 56px;
  background-color: $surface-secondary;
  min-height: 100vh;
}

.header {
  width: 100%;
  padding: 30px 50px;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    @include text-h2;
  }

  .subtitle {
    @include text-body;
    color: $base-grey-50;
  }
}
