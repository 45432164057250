@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  height: 100vh;
  width: 100vw;
  background-color: $white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  overflow: auto;

  .wrapper {
    max-width: 450px;
    width: 90%;
  }
}
