@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/spinners';
@import 'old-colors';
@import 'fonts';
@import 'colors';
@import 'mixins';
@import 'utils';
@import 'statuses';
@import 'grid';
@import 'tabs';
@import 'tooltip';
@import 'charts';
@import 'components';
@import 'typography';

body {
  margin: 0;
  font-family: inter, sans-serif;
  overflow: auto !important;

  &.modal-open {
    overflow: hidden;
  }
}

p,
h1,
h2,
h3,
h4,
h5,
label,
span,
input,
li {
  color: $base-grey;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: urw-gothic, sans-serif;
}

/***** INPUT *****/
input:-webkit-autofill {
  box-shadow: 0 0 0 30px $white inset !important;
  -webkit-box-shadow: 0 0 0 30px $white inset !important;
}

/***** BADGE *****/
.badge {
  padding: 0.4em 1em;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;

  &Small {
    font-size: 9px;
    font-weight: 500;
    text-transform: none;
  }
}

/***** REFERENCES *****/
span.highlight-reference {
  color: #2e263e;
  font-weight: bold;
}

/***** FORM *****/
form .inline {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
form .inline > * {
  width: 47%;
}
