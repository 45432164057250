@use 'styles/variables' as *;

.root {
  display: flex;
  justify-content: center;
}

.content {
  border-top: $grey-border;
}

.badge {
  background-color: $neutral-06;
}
