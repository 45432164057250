@use 'styles/mixins' as *;

.root {
  position: relative;
  width: 400px;
  min-height: 70px;
  border-radius: 8px;
  background-color: $white;
  border: solid 1px $brand-primary-5;
  display: flex;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;

  &.--disabled {
    cursor: not-allowed;

    .description {
      opacity: 0.4;
    }
  }

  &.--selected {
    background-color: $brand-primary-90;

    .title,
    .name,
    .details {
      color: $white;
    }
  }

  &:hover:not(.--disabled) {
    background-color: $brand-primary-90;

    .title,
    .name,
    .details {
      color: $white;
    }

    .actionButton {
      background-color: $surface-secondary;
      color: $brand-primary-200;
    }
  }
}

.tag {
  position: absolute;
  top: -5px;
  right: 5px;
  text-transform: uppercase;
  font-size: 11px;
  padding: 2px 6px;
  background-color: $status-pending-secondary;
  color: $white;
  font-weight: 500;
  border-radius: 5px;
}

.title {
  font-size: 12px;
  color: $brand-primary-160;
  font-weight: 500;
  transition: color 0.5s;
  -webkit-transition: color 0.5s;
}

.name {
  font-size: 18px;
  color: $brand-primary-200;
  font-weight: bold;
  transition: color 0.5s;
  -webkit-transition: color 0.5s;
}

.details {
  transition: color 0.5s;
  -webkit-transition: color 0.5s;
}
