@use 'styles/mixins' as *;

.root {
  display: flex;
  align-items: center;
  flex-direction: column;

  .icon {
    min-width: 40px;
    max-width: 70px;
    max-height: 70px;
  }

  .headertitle {
    color: $brand-primary-200;
  }

  .title {
    font-size: 15px;
    color: $brand-primary-200;
  }

  .subtitle {
    font-size: 14px;
    white-space: pre-line;
    width: 100%;
    text-align: center;
    color: $brand-primary-160;
  }

  .stepsWrapper {
    display: flex;

    .step {
      position: relative;
      @include flex-box-centered;
      margin: 0 10px;
      font-weight: 600;
      width: 20px;
      height: 20px;
      color: $brand-primary-200;
      border-radius: 100%;
      border: 1px solid $base-grey-10;
      background-color: $surface-secondary;

      &:not(:last-of-type) {
        &::before {
          top: 0;
          left: 100%;
          height: 10px;
          position: absolute;
          content: '';
          border-bottom: $grey-border;
          width: 30px;
          z-index: 0;
        }
      }
    }
  }
}
