@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.formInput {
  margin-bottom: 0.5em;
  text-align: center;
}

.link {
  font-size: 12px;
  color: $brand-primary;
  cursor: pointer;
  transition: color 0.4s;
  -webkit-transition: color 0.4s;
  font-weight: 500;
  padding-top: 30px;

  &:hover {
    text-decoration: underline;
    color: $brand-primary-105;
  }
}
