@use 'styles/mixins' as *;

@mixin previewLabel {
  @include text-body;
  position: absolute;
  bottom: 0;
}

.root {
  position: relative;

  &WithLabel {
    padding-bottom: 3rem;
  }

  .scaleWrapper {
    width: 100%;
    display: flex;
    border-radius: 6px;
    border: 1px solid $base-grey-10;
    overflow: hidden;

    .option {
      @include flex-box-centered;
      flex: 1;
      height: 4rem;
      background-color: $surface-secondary;
      cursor: pointer;

      &:not(:last-of-type) {
        border-right: 1px solid $base-grey-10;
      }

      &Selected {
        background-color: $brand-color;
        color: $white;
      }
    }
  }

  .labelStart {
    @include previewLabel;
    left: 0;
  }

  .labelEnd {
    @include previewLabel;
    right: 0;
  }
}

.disabled {
  cursor: not-allowed !important;
}
