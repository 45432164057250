@use 'styles/mixins' as *;

.root {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 1em 0;
  border-radius: 10px;
  border: 1px solid $base-grey-10;
  background-color: $white;
  transition: border-radius 0.4s;
  padding: 15px 15px 15px 40px;

  @include viewportMedium {
    flex-wrap: wrap;
    gap: 5px;
    justify-content: space-between;
  }
}

.userRef {
  color: $brand-primary-120;
}

.date,
.time {
  margin-right: 20px;
  white-space: nowrap;
}

.wrap {
  word-break: break-word;
}

.icon {
  height: 20px;
  padding: 1px 0;
  color: $brand-primary-40;
  margin-right: 10px;

  &:global(.feather-check-circle) {
    color: $status-completed;
  }

  &:global(.feather-alert-circle) {
    color: $status-error;
  }

  &:global(.feather-alert-triangle) {
    color: $status-pending;
  }
}

.description {
  width: 100%;

  a {
    color: $brand-primary;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    margin-left: 3px;
    margin-right: 3px;
  }

  p {
    margin: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  b {
    text-wrap: nowrap;
  }
}

.userLink {
  color: $brand-primary;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}
