@use 'styles/mixins' as *;

@mixin previewLabel {
  @include text-body;
  position: absolute;
  bottom: -3rem;
}

.starsPreview {
  position: relative;
  margin-bottom: 3rem;

  .starsWrapper {
    display: flex;
    justify-content: space-between;
  }

  .star {
    fill: $surface-secondary;
    color: $base-grey-10;
  }

  .labelStart {
    @include previewLabel;
    left: 0;
  }

  .labelEnd {
    @include previewLabel;
    right: 0;
  }
}
