@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  margin-bottom: 25px;

  .number {
    color: $base-grey-90;
    display: inline-block;
    text-align: center;
    height: 20px;
    width: 20px;
    background-color: $surface-secondary;
    border-radius: 50%;
    border: 1px solid $base-grey-10;
    margin-right: 10px;
    min-width: 20px;
    z-index: 2;
  }

  .question {
    color: #000000;
    font-weight: 500;
  }

  .answer {
    .scaleLabel {
      color: #2e263e;

      &:nth-child(1) {
        padding-right: 10px;
      }
      &:nth-child(9) {
        padding-left: 10px;
      }
    }

    .scale {
      color: $base-grey-10;
      background-color: $surface-secondary;
      margin: 0 2px;
      display: inline-block;
      width: 18px;
      height: 18px;
      font-size: 12px;
      border-radius: 50%;
      border: 1px solid $base-grey-10;
      text-align: center;

      &.selected {
        background-color: $base-grey-50;
        color: $white;
        font-weight: 500;
      }
    }
  }
}
