.root {
  padding: 10px;
  width: 100%;

  &Opened {
    .header .toggleButton {
      transform: rotate(180deg);
    }
  }
}

.header {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: 10px;
  justify-items: center;
  align-items: center;

  &FullWith {
    & > * {
      grid-column-start: span 11;
      justify-self: start;
    }
  }

  .toggleButton {
    transform: rotate(0deg);
    transition:
      transform 0.4s,
      opacity 0.4s 0s;
    grid-column-start: 12;
    justify-self: end;
  }
}
