@use 'styles/mixins' as *;

.root {
  @include flex-box-centered;
  background: $background-gradient;
  width: 100vw;
  height: 100vh;

  .card {
    overflow: scroll;
    max-height: 90vh;
  }

  .content {
    width: 500px;
    padding: 60px;
  }

  .logo {
    @include flex-box-centered;
    flex-direction: column;
    max-width: 25rem;
    cursor: pointer;
  }

  .inlineInputs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    & > div {
      width: 45%;
    }
  }

  .footer {
    @include flex-box-centered;
    overflow: hidden;
    color: $base-grey-50;
    background-color: $surface-secondary;
    padding: 0 !important;
    font-size: 1.1rem;

    p {
      margin: 5px;
    }

    a {
      @include link;
    }
  }
}
